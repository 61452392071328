import { graphql } from "@/federatedGql/gql"

export const GET_CART_LIST_ITEMS = graphql(`
  query getCartListItems($regionCode: String) {
    cartListV2 {
      ... on Cart {
        __typename
        items {
          qty
          notes
          id
          company {
            __typename
            isUmkk
            name
            slug
            id
            institusiId
            status
            shippingAddresses {
              latitude
              longitude
              villageAreaCode
              id
              isMainAddress
              regionDetail {
                cityName
              }
            }
          }
          product {
            status
            id
            name
            status
            type
            isBuyable
            updatedAt
            slug
            username
            subType
            preOrder {
              sla
            }
            addOns {
              id
              name
              type
              variant {
                id
                name
                price
              }
              tax {
                ppnPercentage
                ppnTypes
              }
            }
            variant {
              imageUrl
              id
              optionValues
              stock
              price
            }
            shipping {
              dimensionUnit
              height
              length
              weight
              weightUnit
              width
              hasSellerShipping
            }
            prices(regionCode: $regionCode) {
              isRegionPrice
              maxPrice
              maxPriceWithTax
              minPrice
              minPriceWithTax
              minPurchase
              selectedRegionPrice {
                id
                parentRegionCode
                price
                priceWithTax
                regionCode
                regionLevel
                regionName
              }
              productWholesalePrices {
                minQuantity
                price
              }
            }
            images {
              id
              imageUrl
            }
            stockUnit {
              primaryUnit
            }
            tax {
              ppnBmPercentage
              ppnPercentage
              ppnTypes
            }
            consolidation {
              name
              pricingScheme
            }
            allowedTransactionMethod
          }
        }
      }
      ... on GenericError {
        __typename
        reqId
        code
        message
      }
    }
  }
`)

export const GET_CART_LIST_ITEMS_DEV = graphql(`
  query getCartListItemsDev($regionCode: String) {
    cartListV2 {
      ... on Cart {
        __typename
        items {
          qty
          notes
          id
          company {
            __typename
            isUmkk
            name
            slug
            id
            institusiId
            status
            shippingAddresses {
              latitude
              longitude
              villageAreaCode
              id
              isMainAddress
              regionDetail {
                cityName
              }
            }
          }
          product {
            status
            id
            name
            status
            subType
            type
            isBuyable
            updatedAt
            slug
            username
            preOrder {
              sla
            }
            addOns {
              id
              name
              type
              variant {
                id
                name
                price
              }
              tax {
                ppnPercentage
                ppnTypes
                taxableRate
              }
            }
            variant {
              imageUrl
              id
              optionValues
              stock
              price
            }
            shipping {
              dimensionUnit
              height
              length
              weight
              weightUnit
              width
              hasSellerShipping
            }
            prices(regionCode: $regionCode) {
              isRegionPrice
              maxPrice
              maxPriceWithTax
              minPrice
              minPriceWithTax
              minPurchase
              selectedRegionPrice {
                id
                parentRegionCode
                price
                priceWithTax
                regionCode
                regionLevel
                regionName
              }
              productWholesalePrices {
                minQuantity
                price
              }
            }
            images {
              id
              imageUrl
            }
            stockUnit {
              primaryUnit
            }
            tax {
              ppnBmPercentage
              ppnPercentage
              ppnTypes
              taxableRate
            }
            consolidation {
              name
              pricingScheme
            }
            allowedTransactionMethod
          }
        }
      }
      ... on GenericError {
        __typename
        reqId
        code
        message
      }
    }
  }
`)

export const GET_PURCHASE_SUMMARY = graphql(`
  query getPurchaseSummary($input: OrderTotalSummaryInput!) {
    orderTotalSummary: orderTotalSummaryV2(input: $input) {
      ... on OrderTotalSummaryResponse {
        __typename
        grandTotalAfterTax
        totalAddOn {
          totalPpn
          totalPrice
          totalQty
        }
        totalAllPpn
        totalAllPpnBm
        totalProduct {
          totalPpn
          totalPpnBm
          totalQty
          totalPrice
        }
        totalShipment {
          totalPrice
          totalPpn
          totalInsuranceFee
          totalPpnInsuranceFee
        }
      }
      ... on GenericError {
        __typename
        message
        reqId
        code
      }
    }
  }
`)

export const GET_CART_NOTIFICATION_COUNT = graphql(`
  query cartItemCount {
    cartListV2 {
      ... on Cart {
        __typename
        items {
          qty
        }
      }
      ... on GenericError {
        __typename
        message
        reqId
        code
      }
    }
  }
`)

export const CART_CONFIG_QUERY = graphql(`
  query cartConfig {
    cartSetting {
      ... on CartSettings {
        __typename
        negotiationThreshold
        PPKCheckoutThreshold
        PPCheckoutLimit
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const CART_CONFIG_TEMPORARY_QUERY = graphql(`
  query cartConfigTemporary {
    cartSetting {
      ... on CartSettings {
        __typename
        negotiationThreshold
        PPKCheckoutThreshold
        PPCheckoutLimit
        PPKPemdaCheckoutThreshold
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_LOWEST_SOLD_PRICE = graphql(`
  query GetLowestSoldPrice($input: [FilterLowestProductSoldPriceInput!]!) {
    lowestProductSoldPrice(input: $input) {
      ... on GenericError {
        __typename
        reqId
        message
      }
      ... on LowestProductSoldPriceRes {
        __typename
        items {
          orderedAt
          productId
          productVariantId
          quantityPurchase
          region {
            cityName
          }
          soldPrice
          stockUnit {
            primaryUnit
            value
          }
        }
      }
    }
  }
`)

export const GET_LOGISTIC_PRICING = graphql(`
  query getLogisticPricing($input: PricingInput!) {
    logisticPricing(input: $input) {
      ... on PricingResponse {
        __typename
        data {
          name
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
