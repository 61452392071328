import { createQuery } from "react-query-kit"
import { type GenericExtractor } from "shared-utils"

import {
  GET_CART_LIST_ITEMS,
  GET_CART_LIST_ITEMS_DEV,
} from "../federated/queries"
import {
  type GetCartListItemsQuery,
  type GetCartListItemsQueryVariables,
} from "@/federatedGql/graphql"
import { federatedGqlClient } from "@/utils/graphqlClient"

export type TCartListItem = GenericExtractor<
  GetCartListItemsQuery["cartListV2"]
>

type TUseGetCartItemsQueryVariables = {
  regionCode: GetCartListItemsQueryVariables
  useDevQuery?: boolean
}

const useGetCartItems = createQuery<
  TCartListItem,
  TUseGetCartItemsQueryVariables
>({
  primaryKey: "cartListItem",
  queryFn: async ({ queryKey: [, { regionCode, useDevQuery }] }) => {
    const result = await federatedGqlClient.request(
      useDevQuery ? GET_CART_LIST_ITEMS_DEV : GET_CART_LIST_ITEMS,
      {
        regionCode: regionCode as string,
      }
    )
    if (result.cartListV2.__typename == "GenericError") {
      throw result.cartListV2
    }
    return result.cartListV2
  },
  retry: 1,
})

export default useGetCartItems
